<template>
  <!--美妆个护-->
  <div>

    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <van-sticky>
          <div class="stickyBox">
            <div class="headdiv">
              <Head />
            </div>
            <fresh-nav :hover="freshNav" />
          </div>
        </van-sticky>

        <!--banner-->
        <fresh-banner :swiper="Banner" />
        <!--grid list-->
        <fresh-grid :category-grip="category" :hover="freshNav" />
        <!--常买清单-->
        <!-- <fresh-buy></fresh-buy> -->
        <!--滑动商品-->
        <fresh-scroll :activity-list="activity" />
        <!--推荐商品-->
        <fresh-list id="dataList" :list="commendList" />

      </div>
    </mescroll>
    <!--底部tab-->
    <Tabbar />
  </div>
</template>
<script>
import mescroll from '@/component/fresh_scroll/mescroll'

import FreshScroll from '@/component/fresh_scroll'
import FreshList from '@/component/fresh_scroll/List'
import FreshBanner from '@/component/fresh_scroll/banner'
import FreshGrid from '@/component/fresh_scroll/grid'
// import FreshBuy from '@/component/fresh_scroll/buy'
import FreshNav from '@/component/fresh_scroll/nav'
import Tabbar from '@/component/Tabbar'
import Head from '@/component/head/head'
import Vue from 'vue'
import { getNav, getrecommendList } from '@/services/fresh'

import { Sticky } from 'vant'

Vue.use(Sticky)

export default {
  name: 'BeautyMakeup',
  components: {
    FreshNav,
    FreshBanner,
    FreshGrid,
    FreshScroll,
    FreshList,
    Head,
    Tabbar,
    mescroll
  },
  data() {
    return {
      mescrollDown: {
        use: true
        // top: "50px",
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      uid: '',
      Banner: '',
      category: '',
      activity: '',
      page: 1,
      pageSize: 10,
      commendList: '',
      freshNav: 'beauty'
    }
  },
  mounted() {
    this.uid = window.localStorage.getItem('uid') || ''
    this.getNav()
    // this.getrecommendList()
  },

  methods: {
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.commendList = []
          // 把请求到的数据添加到列表
          this.commendList = this.commendList.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },

    getListDataFromNet(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const opt = {
        // categoryId: "8783,8797",
        categoryId: '8787,8811',
        page: pageNum,
        pageSize: pageSize
      }
      getrecommendList(opt).then((res) => {
        if (Number(res.code) === 200) {
          successCallback(res.data)
        }
      })
    },

    getNav() {
      const opt = {
        categoryId: '8787,8811',
        channelId: 3,
        uid: this.uid
      }
      getNav(opt).then((res) => {
        const _this = this
        _this.Banner = res.data.banner
        _this.category = res.data.category
        _this.activity = res.data.activity
      })
    }
    // getrecommendList () {
    //   let _this = this
    //   let opt = {
    //     categoryId: '8787,8811',
    //     page: _this.page,
    //     pageSize: _this.pageSize
    //   }
    //   getrecommendList(opt).then(res => {
    //     this.commendList = res.data
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
body {
  background: #f5f7fa;
  padding-bottom: 100px;
}

.headdiv {
  padding: 0 12px;
}

.stickyBox {
  height: 82px;
  background: #fff;
}
</style>
